import { ActionTree, MutationTree } from 'vuex';
import { RootState } from '@/interfaces/stores/RootState';
import { Campaign } from '@/interfaces/models/Campaign';
import CampaignApiService from '@/api/http/CampaignApiService';
import Filter from '@/interfaces/api/Filter';
import { Pagination } from '@/interfaces/api/Pagination';
import { Page } from '@/interfaces/api/Page';
import { AxiosResponse } from 'axios';

interface CampaignState {
  items: Campaign[];
  active: Campaign | null;
  filter: Filter;
  pagination: {
    total: number;
  };
}

const api: CampaignApiService = new CampaignApiService();

const state: CampaignState = {
  items: [],
  active: null,
  filter: {},
  pagination: {
    total: 10,
  },
};

const actions: ActionTree<CampaignState, RootState> = {
  fetch({ commit, state }, data: { page: Page }) {
    return api.getCampaigns(data.page, state.filter).then((res: AxiosResponse<Campaign[] | Pagination<Campaign>>) => {
      commit('setItems', res.data);
    });
  },
  store({ commit }, data: Partial<Campaign>) {
    return api.store(data).then((res: AxiosResponse<Campaign>) => {
      commit('addItem', res.data);
    });
  },
  show({ commit }, data: { id: string }) {
    return api.show(data).then((res: AxiosResponse<Campaign>) => {
      commit('setActive', res.data);
    });
  },
  update({ commit }, data: { data: Partial<Campaign>; id: string }) {
    return api.update(data).then((res: AxiosResponse<Campaign>) => {
      commit('update', res.data);
    });
  },
  delete({ commit }, data: { id: string }) {
    return api.softDelete(data).then(() => {
      commit('delete', { id: data.id });
    });
  },
  setFilter({ commit }, filter: Filter) {
    commit('setFilter', filter);
  },
};

const mutations: MutationTree<CampaignState> = {
  setItems(state: CampaignState, data: Pagination<Campaign>) {
    state.items = (data.data as Campaign[]) || data;

    if (data.total) {
      state.pagination.total = data.total;
    }
  },
  setActive(state: CampaignState, data: Campaign) {
    state.active = data;
  },
  addItem(state: CampaignState, data: Campaign) {
    state.items.push(data);
  },
  update(state: CampaignState, data: Campaign) {
    state.items = [...state.items.filter((r: Campaign) => r._id !== data._id), data];
  },
  delete(state: CampaignState, data: { id: string }) {
    state.items = [...state.items.filter((r: Campaign) => r._id !== data.id)];
  },
  setFilter(state: CampaignState, data: Filter) {
    state.filter = data;
  },
};

export default {
  namespaced: true,
  state,
  actions,
  mutations,
};
