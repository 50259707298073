import ApiService from '@/api/http/ApiService';
import { Campaign } from '@/interfaces/models/Campaign';
import { Page } from '@/interfaces/api/Page';
import Filter from '@/interfaces/api/Filter';
import { Pagination } from '@/interfaces/api/Pagination';
import axios, { AxiosPromise } from 'axios';

export default class CampaignApiService extends ApiService<Campaign> {
  constructor() {
    super('notification/campaigns', 'v1');
  }

  public getCampaigns(page: Page, filter: Filter): AxiosPromise<Campaign[] | Pagination<Campaign>> {
    if (page) {
      return axios.get(`${this.getBaseUrl()}/`, {
        params: {
          page,
          limit: 10,
          ...(filter.customerGroup
            ? { customerGroup: filter.customerGroup }
            : filter.venue
            ? { venue: filter.venue }
            : {}),
        },
      });
    }

    return axios.get(`${this.getBaseUrl()}`);
  }
}
