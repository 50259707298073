import { Model } from '@/interfaces/models/Model';
import cookie from 'js-cookie';
import { Permission } from '@/enums/Permission';
import { Venue } from '@/interfaces/models/Venue';
import axios from 'axios';
import QRCode from 'qrcode';

export function bindItemBy<S extends Model>(item: S, fields: string[], target: any): void {
  for (const field of fields) {
    // @ts-ignore
    if (item && item[field] && Array.isArray(item[field])) {
      // @ts-ignore
      target[field] = item[field];
      // @ts-ignore
    } else if (item[field] && typeof item[field] === 'object') {
      // @ts-ignore
      for (const key of Object.keys(item[field])) {
        // @ts-ignore
        target[field][key] = item[field][key];
      }
      // @ts-ignore
    } else if (item && item[field]) {
      // @ts-ignore
      target[field] = item[field];
    }
  }
}

export function index(obj: any, is: string | any[], value?: any): any {
  if (typeof is === 'string') {
    return index(obj, is.split('.'), value);
  } else if (is.length === 1 && value !== undefined) {
    return (obj[is[0]] = value);
  } else if (is.length === 0) {
    return obj;
  } else {
    return index(obj[is[0]], is.slice(1), value);
  }
}

export function cloneDeep(obj: any): any {
  return JSON.parse(JSON.stringify(obj));
}

export function toUrl(data: BlobPart, name: string, options?: object): void {
  const url = window.URL.createObjectURL(new Blob([data], options ? options : {}));
  const link = document.createElement('a');
  link.href = url;
  link.setAttribute('download', name);
  document.body.appendChild(link);
  link.click();
}

export function toDownloadImage(data: string, name: string, target = '_blank'): void {
  const link: HTMLAnchorElement = document.createElement('a');
  link.href = data;
  link.target = target;
  link.setAttribute('download', name);
  document.body.appendChild(link);
  link.click();
}

export function getListOfEmailsRegexp(): RegExp {
  return /^[\w.%+-]+@[A-Za-z0-9.-]+\.[A-Z|a-z]{2,}(?:,[\w.%+-]+@[A-Za-z0-9.-]+\.[A-Z|a-z]{2,})*$/;
}

export function getListOfIntegersRegexp(): RegExp {
  return /^\d+(?:,\d+)*$/;
}

export function getTimeRegexp(): RegExp {
  return /^(?:[01]\d|2[0-3]):[0-5]\d$/;
}

export function getPermissions(): string[] | undefined {
  const permissions: string[] | string | undefined = cookie.get('permissions');
  if (permissions) {
    return JSON.parse(permissions as string);
  }
}

export function getUrlRegexp(): RegExp {
  return /^[a-zA-Z0-9-]+\.[a-zA-Z]{2,}$/;
}

export function can(perm: Permission) {
  return hasRole('admin') || hasPermission(perm);
}

export function isAuthorized(): boolean {
  let token: string | undefined = cookie.get('token');

  // todo - if token the bigger than 4kb
  if (!token && cookie.get('numTokenChunks')) {
    token = getJoinedTokenChunk();
  }

  if (!token) {
    return false;
  }

  return true;
}

export function getJoinedTokenChunk(): string {
  let value = '';
  const numChunks = Number(cookie.get('numTokenChunks'));

  for (let i = 0; i < numChunks; i++) {
    const cookieName = `tokenChunk${i}`;
    const chunk = decodeURIComponent(cookie.get(cookieName)!);
    value += chunk;
  }
  return value;
}

export function hasPermission(perm: Permission) {
  const permissions: string[] | undefined = getPermissions();

  if (permissions && permissions.indexOf(Permission.ADMIN) > -1) {
    return true;
  }

  if (permissions && perm) {
    return permissions.indexOf(perm) > -1;
  }

  return false;
}

export function hasAnyPermission(perm: Permission[]): boolean {
  const permissions: string[] | undefined = getPermissions();

  if (!permissions) {
    return false;
  }

  if (permissions.indexOf(Permission.ADMIN) > -1) {
    return true;
  }

  // @ts-ignore
  return permissions.some((p: Permission) => perm.includes(p));
}

export function hasRole(r: string): boolean {
  const role: string | undefined = cookie.get('role');

  if (role) {
    return r === role;
  }

  return false;
}

export function arraysEqual(arr1: any[], arr2: any[]): boolean {
  if (!Array.isArray(arr1) || !Array.isArray(arr2) || arr1.length !== arr2.length) {
    return false;
  }

  const arr1Sorted: any[] = arr1.concat().sort();
  const arr2Sorted: any[] = arr2.concat().sort();

  for (let i: number = 0; i < arr1.length; i += 1) {
    if (arr1Sorted[i] !== arr2Sorted[i]) {
      return false;
    }
  }

  return true;
}

export function getVenuesFromStorage(): string[] {
  let venues: string[] | string = localStorage.getItem('venues')!;
  if (venues) {
    venues = JSON.parse(venues);
  } else {
    venues = [];
  }

  return venues as string[];
}

export function venueFilter(item: Venue, queryText: string): boolean {
  if (item.readableId) {
    return (
      item.name.toLowerCase().indexOf(queryText.toLowerCase()) > -1 ||
      item.readableId.toLowerCase().indexOf(queryText.toLowerCase()) > -1
    );
  }
  return item.name.indexOf(queryText.toLowerCase()) > -1;
}

export function convertEmptyToNull(o: any): any {
  if (!o) {
    return null;
  }
  const keys: string[] = Object.keys(o);
  for (const key of keys) {
    if (o[key] === '') {
      o[key] = null;
    }
  }

  return o;
}

export function debounce(fn: any, delay: number = 500): void {
  let timeoutId: any;
  clearTimeout(timeoutId);
  // @ts-ignore
  const self = this;
  const args = arguments;
  timeoutId = setTimeout(() => {
    fn.apply(self, args);
  }, delay);
}

// exp: -1, 0, 1;
export function decimalAdjust(type: 'round' | 'floor' | 'ceil', value: any, exp: number) {
  if (typeof exp === 'undefined' || +exp === 0) {
    return Math[type](value);
  }
  value = +value;
  exp = +exp;
  // if not a number
  if (isNaN(value) || !(typeof exp === 'number' && exp % 1 === 0)) {
    return NaN;
  }

  value = value.toString().split('e');
  value = Math[type](+(value[0] + 'e' + (value[1] ? +value[1] - exp : -exp)));

  value = value.toString().split('e');
  return +(value[0] + 'e' + (value[1] ? +value[1] + exp : exp));
}

export function isAxiosError(error: any) {
  return axios.isAxiosError(error);
}

export function replaceCommaWithDot(value: string): string {
  if (typeof value === 'string') {
    return value.replace(',', '.');
  }
  return value;
}

export function generateQRCode(value: string) {
  return QRCode.toDataURL(value);
}

export function copyToClipboard(text: string, isHtml = false): void {
  const isSupported = Boolean(navigator && 'clipboard' in navigator);
  if (!isSupported) return;
  if (isHtml) {
    // @ts-ignore
    navigator.clipboard.write([
      // @ts-ignore
      new ClipboardItem({
        'text/html': new Blob([text], { type: 'text/html' }),
      }),
    ]);
  } else {
    navigator?.clipboard.writeText(text);
  }
}

export function scrollToFailedField(): void {
  const el = document.getElementsByClassName('error--text');

  if (el?.length) {
    el[0].scrollIntoView();
  }
}

export function toDownload(data: string | BlobPart, name: string, target = '_blank'): void {
  let url: string;
  if (typeof data === 'string' && (data.startsWith('http') || data.startsWith('https'))) {
    url = data;
  } else {
    url = window.URL.createObjectURL(new Blob([data]));
  }
  const link: HTMLAnchorElement = document.createElement('a');
  link.href = url;
  link.target = target;
  link.setAttribute('download', name);
  document.body.appendChild(link);
  link.click();
}
